body.ready-for-edit [data-inline-edit], body.ready-for-edit [data-inline-editor] {
  display: inline-block;
  position: relative;
  white-space: pre-line;
}
body.ready-for-edit [data-inline-edit]:after, body.ready-for-edit [data-inline-editor]:after {
  position: absolute;
  left:   -6px;
  right:  -6px;
  top:    -6px;
  bottom: -6px;
  content: '';
  border-radius: 3px;
  cursor: text;
  border: 1px dashed rgba(0,0,250,0.3);
}

[data-ie-helper] {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  position: fixed;
  z-index: 800;
  left: 20px;
  bottom: 20px;
  opacity: 0.7;
  background: rgba(255,255,255,0.8);
  color: #000;
  padding: 4px 7px;
  line-height: 1.7em;
  border-radius: 3px;

  kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: .2rem;
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  }

  [data-ie-start] {
    display: block;
  }
  [data-ie-save] {
    display: none;
  }
}

body.ready-for-edit [data-ie-helper] {
  [data-ie-start] {
    display: none;
  }
  [data-ie-save] {
    display: block;
  }
}

[data-inline-image] {
  display: none;
  position: absolute;
  left:   0px;
  top:    0px;
  right:  0px;
  bottom: 0px;
  min-height: 30px;
  border-radius: 10px;
  transition: background 0.25s linear;

  input[type="file"] {
    opacity: 0;
    position: absolute;
    left:   5px;
    top:    5px;
    right:  5px;
    bottom: 5px;

    width: 100%;
    cursor: pointer;
  }
  .image-hint {
    font-size: 13px;
    font-family: monospace;
    background-color: #333;
    color: #fff;
    padding: 5px 8px;
    border-radius: 3px;
  }
  .alt-edit {
    letter-spacing: 0.08em;
    line-height: 18px;
    color: #000;
    position: absolute;
    left: 10px;
    top: 10px;
    height: 22px;
    width: 80%;
    max-width: 230px;
    padding: 5px;
    z-index: 10;
    padding: 0;
    font-size: 13px;
  }
  span.alt-edit {
    color: #555;
    z-index: 15;
    display: block;
    padding: 3px;
    width: 29px;
  }
  input.alt-edit {
    background-color: rgba(255,255,255,0.85);
    border-radius: 3px;
    padding-left: 32px;
  }

  .alt-edit input {
    height: 20px;
    padding: 2px 5px;
  }
}

[data-inline-bg-hash] [data-inline-image] {
  left:   10px;
  top:    10px;
  right:  10px;
  bottom: 10px;
}

body.ready-for-edit [data-inline-image] {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.mce-container.mce-menubar {
  display: none;
}
.mce-flow-layout-item {
  margin: 0 2px;
}
.mce-tinymce-inline.mce-container.mce-panel {
  border-radius: 5px;
}
.mce-tinymce-inline.mce-container.mce-panel > .mce-container-body.mce-abs-layout {
  border-radius: 5px;
}
.mce-tinymce-inline.mce-container {
  box-shadow: 0 2px 15px rgba(0,0,0,0.1);
  background: #fff;
}
.mce-btn {
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

